export const LOCAL_INSTIGAR_URL = "http://localhost:3000/organizations";
export const PROD_INSTIGAR_URL = "http://instigar.in/organizations";

export const LOCAL_DOMAIN = "http://localhost:8081";
export const PROD_DOMAIN = "api.instigar.in";

export const API_REQUEST_TIMEOUT = 5000;

export const POST_METHOD = "POST";

export const SIGN_UP_URL = "/api/v1/home/signup";
export const SIGN_IN_URL = "/api/v1/auth/signin";
export const RESET_PASSWORD_URL = "/api/v1/home/resetPassword";