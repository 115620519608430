import React, { Component } from 'react';

import { validateEmail } from '../utils/utils';
import InputField from "./Common/InputField";

class ResetPasswordLink extends Component {
    state = {
        fieldValues: {
            email: "",
        },
        errors: {
            email: false,
        },
        apiResponse: ''
    }

    setErrorState = (name, value) => {
        const { errors } = this.state;
        errors[name] = value;
        this.setState({ errors });
    };

    setFieldValueState = (name, value) => {
        const { fieldValues, errors } = this.state;
        fieldValues[name] = value;
        if (value) {
            errors[name] = false;
        }
        this.setState({ fieldValues, errors });
    };

    onKeyPressHandler = (e) => {
        if(e.which === 13 || e.key === 12) {
            this.resetPassword();
        }
    }

    resetPassword = () => {
        const { fieldValues, errors } = this.state;
        if (fieldValues.email !== "" && !errors.email) {
            this.props.resetPasswordLink(fieldValues, (message, status) => {
                this.setState({apiResponse: message, status});
            });
        }
    }

    render() {
        const { showSignIn } = this.props;
        const { fieldValues, errors, apiResponse, status } = this.state;
        const { email } = fieldValues;

        const apiClassName = status === 200 ? 'success-text' : 'error-class';
        return (
            <section className="contact-section section-container ftco-no-pb" id="signup-section">
                <title>Reset password Instigar</title>
                <div className="container">
                    <div className="row no-gutters block-9">
                        <div className="col-md-12 col-sm-12 order-md-last d-flex section-content-center">
                            <div className="bg-light contact-form reset-link-form">
                                <div className="form-head icon-instigar-logo"></div>
                                <div className="form-group header-text">
                                    <div>
                                        <h2>Did you Forget your password ?</h2>
                                    </div>
                                </div>
                                <div className="form-group header-text">
                                    <div>
                                        <h4>That's okay ..,</h4>
                                        <h4>Enter your email address you have used to register with us and we'll send you a reset link !</h4>
                                    </div>
                                </div>
                                <div className="form-group">
                                    <InputField
                                        type="email"
                                        name="email"
                                        placeholder="Enter your email id"
                                        isRequired
                                        setFieldValueState={this.setFieldValueState}
                                        setErrorState={this.setErrorState}
                                        value={email}
                                        showError={errors.email}
                                        validate={validateEmail}
                                        onKeyPressHandler={this.onKeyPressHandler}
                                    />
                                </div>
                                <div className="form-group">
                                    <span className={apiClassName}>{ apiResponse }</span>
                                </div>
                                <div className="form-group btn-form-group">
                                    <input type="submit" value="Submit" className="btn btn-primary py-3 px-5" onClick={this.resetPassword}></input>
                                    <span className="aLink forgot-password" onClick={showSignIn}>Cancel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default ResetPasswordLink;