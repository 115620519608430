import React, {Component} from 'react';
import Select from "react-select";
import DatePicker from "react-date-picker";

import InputField from './Common/InputField';

import { validateEmail, validatePhone, buildRedirectUrl } from '../utils/utils';
import {SELECT_DROP_DOWN_STYLES, GENDER } from "../constants";


class RegistrationSection extends Component {

  state = {
    fieldValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      confirmPassword: '',
      dateOfBirth: '',
      gender: '',
    },
    errors: {
      firstName: false,
      lastName: false,
      email: false,
      phone: false,
      password: false,
      confirmPassword: false,
      dateOfBirth: false,
      gender: false,
    },
    errorMessage: null,
    gender: {label: 'select gender', value: ''},
  };

  setErrorState = (name, value) => {
    const { errors } = this.state; 
    errors[name] = value;
    this.setState({ errors });
  };

  setFieldValueState = (name, value) => {
    const { fieldValues, errors } = this.state;
    fieldValues[name] = value;
    if (value) {
      errors[name] = false;
    }
    this.setState({ fieldValues, errors });
  };

  onKeyPressHandler = (e) => {
    if(e.which === 13 || e.key === 12) {
      this.submitRegistration();
    }
  }


  submitRegistration = () => {
    let isValid = true;
    const { fieldValues, errors } = this.state;
    Object.keys(fieldValues).forEach(key => {
      if (fieldValues[key] === '' || fieldValues[key] === null || errors[key]) {
        isValid = false;
        errors[key] = true;
      }
    });
    if (isValid) {
      this.props.createUser(fieldValues, (success, errorMessage) => {
        if(success) {
          buildRedirectUrl();
        } else {
          this.setState({ errorMessage });
        }
      });
    } else {
      this.setState({ errors });
    }
  };

  verifyPassword = () => {
    const { password, confirmPassword } = this.state.fieldValues;
    return !confirmPassword || password === confirmPassword;
  };

  onGenderChange = (e) => {
    const { fieldValues, errors } = this.state;
    if (e.value !== '') {
      errors.gender = false;
    } else {
      errors.gender = true;
    }
    fieldValues.gender = e.value;
    this.setState({ fieldValues, errors, gender: e });
  }

  setDateOfBirth = (date) => {
    const { fieldValues, errors } = this.state;
    if (date) {
      errors.dateOfBirth = false;
    } else {
      errors.dateOfBirth = true;
    }
    fieldValues.dateOfBirth = date;
    this.setState({ fieldValues, errors });
  }

  render() {
    const { toggleSignIn } = this.props;
    const { fieldValues, errors, gender, errorMessage } = this.state;
    const { firstName, lastName, email, phone, password, confirmPassword, dateOfBirth } = fieldValues;
    return (
        <section className="contact-section section-container ftco-no-pb" id="signup-section">
          <title>SignUp-Instigar</title>
          <div className="container">
            <div className="row no-gutters block-9">
              <div className="col-md-12 col-sm-12 order-md-last d-flex section-content-center">
                <div className="bg-light contact-form sign-up-form">
                  <div className="form-head icon-instigar-logo"></div>
                  {errorMessage &&
                    <div className="form-group">
                      { errorMessage && <div className="col-md-12 col-sm-12 mb-4 login_error error-class">{errorMessage}</div>}
                    </div>
                  }
                  <div className="form-group">
                    <div className="col-md-6 col-sm-6 mb-4">
                      <InputField
                          type="text"
                          name="firstName"
                          placeholder="First Name"
                          isRequired
                          setErrorState={this.setErrorState}
                          setFieldValueState={this.setFieldValueState}
                          value={firstName}
                          showError={errors.firstName}
                          onKeyPressHandler={this.onKeyPressHandler}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb-4">
                      <InputField
                          type="text"
                          name="lastName"
                          placeholder="Last Name"
                          isRequired
                          setErrorState={this.setErrorState}
                          setFieldValueState={this.setFieldValueState}
                          value={lastName}
                          showError={errors.lastName}
                          onKeyPressHandler={this.onKeyPressHandler}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-6 col-sm-6 mb-4">
                      <DatePicker
                          onChange={this.setDateOfBirth}
                          value={dateOfBirth}
                          maxDate={new Date()}
                          className={`date-form-control ${errors.dateOfBirth ? 'input-error' : ''}`}
                          format="dd-MM-yyyy"
                          dayPlaceholder="Date of Birth dd"
                          monthPlaceholder="mm"
                          yearPlaceholder="yyyy"
                      />
                    </div>
                    <div className="col-md-3 col-sm-3 mb-4">
                      <InputField
                          type="tel"
                          name="phone"
                          placeholder="Enter Mobile Number"
                          isRequired
                          setErrorState={this.setErrorState}
                          setFieldValueState={this.setFieldValueState}
                          value={phone}
                          validate={validatePhone}
                          showError={errors.phone}
                          onKeyPressHandler={this.onKeyPressHandler}
                      />
                    </div>
                    <div className="col-md-3 col-sm-3 mb-4">
                      <Select
                          className={`select-school-custom custom-dropdown ${errors.gender ? 'input-error' : ''}`}
                          value={gender}
                          onChange={this.onGenderChange}
                          options={GENDER}
                          styles={SELECT_DROP_DOWN_STYLES}
                      ></Select>
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-12 col-sm-12 mb-4">
                      <InputField
                          type="email"
                          name="email"
                          placeholder="Enter Your Email"
                          isRequired
                          setErrorState={this.setErrorState}
                          setFieldValueState={this.setFieldValueState}
                          value={email}
                          validate={validateEmail}
                          showError={errors.email}
                          onKeyPressHandler={this.onKeyPressHandler}
                      />
                    </div>
                  </div>

                  <div className="form-group">
                    <div className="col-md-6 col-sm-6 mb-4">
                      <InputField
                          type="password"
                          name="password"
                          placeholder="Enter your password"
                          isRequired
                          setErrorState={this.setErrorState}
                          setFieldValueState={this.setFieldValueState}
                          value={password}
                          validate={this.verifyPassword}
                          showError={errors.password}
                          onKeyPressHandler={this.onKeyPressHandler}
                      />
                    </div>
                    <div className="col-md-6 col-sm-6 mb-4">
                      <InputField
                          type="password"
                          name="confirmPassword"
                          placeholder="Re Enter your password"
                          isRequired
                          setErrorState={this.setErrorState}
                          setFieldValueState={this.setFieldValueState}
                          value={confirmPassword}
                          validate={this.verifyPassword}
                          showError={errors.confirmPassword}
                          onKeyPressHandler={this.onKeyPressHandler}
                      />
                    </div>
                  </div>

                  <div className="form-group btn-form-group">
                    <input type="submit" value="Sign Up" className="btn btn-primary py-3 px-5" onClick={this.submitRegistration}></input>
                  </div>
                  <div className="form-group btn-form-group btn-form-link">
                    <p> Already have an Account,
                      <span className="aLink" onClick={toggleSignIn}> SignIn</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
    );
  }
}

export default RegistrationSection;