import React, { Component } from 'react';
import InputField from './Common/InputField';

import { validateEmail, buildRedirectUrl } from '../utils/utils';

class SignInSection extends Component {

    state = {
        fieldValues: {
            username: "",
            password: "",
        },
        errors: {
            username: false,
            password: false,
        },
        showErrorScreen: false,
        errorMessage: null,
    }

    setErrorState = (name, value) => {
        const { errors } = this.state;
        errors[name] = value;
        this.setState({ errors });
    };

    setFieldValueState = (name, value) => {
        const { fieldValues, errors } = this.state;
        fieldValues[name] = value;
        if (value) {
            errors[name] = false;
        }
        this.setState({ fieldValues, errors, showErrorScreen: false });
    };

    onKeyPressHandler = (e) => {
        if(e.which === 13 || e.key === 12) {
            this.submitLogin();
        }
    }

    submitLogin = () => {
        let isValid = true;
        const { fieldValues, errors } = this.state;
        const { loginUser } = this.props;
        Object.keys(fieldValues).forEach(key => {
            if (fieldValues[key] === '' || errors[key]) {
                isValid = false;
                errors[key] = true;
            }
        });
        if (isValid) {
            loginUser(fieldValues, (success, errorMessage) => {
                if(success) {
                    buildRedirectUrl();
                } else {
                    this.setState({ showErrorScreen: true, errorMessage: errorMessage });
                }
            });
        } else {
            this.setState({ errors });
        }
    };

    render() {

        const { toggleSignIn, showForgotPassword } = this.props;
        const { username, password, showErrorScreen, errorMessage } = this.state;

        return (
            <section className="contact-section section-container ftco-no-pb" id="signup-section">
                <title>SignIn-Instigar</title>
                <div className="container">
                    <div className="row no-gutters block-9">
                        <div className="col-md-12 col-sm-12 order-md-last d-flex section-content-center">
                            <div className="bg-light contact-form sign-in-form">
                                <div className="form-head icon-instigar-logo"></div>
                                <div className="form-group">
                                    <span className="error-class">{ showErrorScreen ? errorMessage || 'Invalid Credentials' : '' }</span>
                                </div>
                                <div className="form-group">
                                    <InputField
                                        type="email"
                                        name="username"
                                        placeholder="Enter your email id"
                                        isRequired
                                        setFieldValueState={this.setFieldValueState}
                                        setErrorState={this.setErrorState}
                                        value={username}
                                        showError={this.state.errors.username}
                                        validate={validateEmail}
                                        onKeyPressHandler={this.onKeyPressHandler}
                                    />
                                </div>
                                <div className="form-group">
                                    <InputField
                                        type="password"
                                        name="password"
                                        placeholder="Enter your password"
                                        isRequired
                                        setFieldValueState={this.setFieldValueState}
                                        setErrorState={this.setErrorState}
                                        value={password}
                                        showError={this.state.errors.password}
                                        onKeyPressHandler={this.onKeyPressHandler}
                                    />
                                </div>

                                <div className="form-group btn-form-group">
                                    <input type="submit" value="Sign in" className="btn btn-primary py-3 px-5" onClick={this.submitLogin}></input>
                                    <span className="aLink forgot-password" onClick={showForgotPassword}>Forgot password ??</span>
                                </div>
                                <div className="form-group btn-form-group btn-form-link">
                                    <p> Don't have one to login,
                                        <span className="aLink" onClick={toggleSignIn}> Create Account</span>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default SignInSection;