import {LOCAL_INSTIGAR_URL, PROD_INSTIGAR_URL} from "../constants/urls";
import {instigarEncode} from "./encoder";

export const getQueryStringParams = () => {
	const url = window.location.href;
	const query = url.split("?")[1];
	return query
		? (/^[?#]/.test(query) ? query.slice(1) : query)
			.split('&')
			.reduce((params, param) => {
					let [key, value] = param.split('=');
					params[key] = value ? decodeURIComponent(value.replace(/\+/g, ' ')) : '';
					return params;
				}, {}
			)
		: {}
};

export const buildRedirectUrl = () => {
	let instigarUrl = PROD_INSTIGAR_URL;
	if (isLocalHostDevelopment()) {
		instigarUrl = LOCAL_INSTIGAR_URL
	}

	const { redirectUrl } = getQueryStringParams();

	if (redirectUrl) {
		try {
			const deCodedRedirectUrl = new URL(decodeURIComponent(redirectUrl));
			const {hostname} = deCodedRedirectUrl;
			if (hostname === 'localhost' || hostname.includes("instigar.in")) {
				instigarUrl = decodeURIComponent(redirectUrl);
			}
		} catch {
			// Do nothing
		}
	}
	window.location.href = instigarUrl;
}

export const convertDateToString = (date) => {
	if (!date) {
		return '';
	}
	const d = new Date(date);
	return `${d.getFullYear()}/${d.getMonth()+1}/${d.getDate()}`;
}

export const validateEmail = (mailValue) =>  {
	return mailValue === '' || (/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(mailValue));
}

export const validatePhone = (inputValue) => {
	return inputValue === '' || (inputValue.match(/[7-9]{1}[0-9]{9}/) && inputValue.length === 10);
}

export const doesErrorExists = (responseObj) => {
	const { response, error } =  responseObj;
	const status = response ? response.status : error.status;
	return error || !response || response.errors || response.error || status < 200 || status >= 300;
}

export const setCookie = (cName, cValue, exDays) => {
	const d = new Date();
	d.setTime(d.getTime() + (exDays*24*60*60*1000));
	const expires = "expires="+ d.toUTCString();
	let domain = ".instigar.in";
	if (isLocalHostDevelopment()) {
		domain = "localhost";
	}
	document.cookie = cName + "=" + cValue + ";" + expires + ";path=/; domain="+domain+";";
}

export const setSessionKeyValue = (key, value) => {
	setCookie(key, instigarEncode(value), 1);
}

export const isLocalHostDevelopment = () => {
	const {hostname} = window.location;
	return hostname === 'localhost';
}