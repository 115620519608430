import {
    createUserService,
    submitLoginService,
    resetPasswordService,
} from '../services/UserProfile';
import {T_USER_ID} from "../constants";
import {buildRedirectUrl, doesErrorExists, setSessionKeyValue} from "../utils/utils";

export function* createUser({ userDetails, callback }) {
    setSessionKeyValue(T_USER_ID, '');
    const createUserResponse = yield createUserService(userDetails);
    let apiSuccess;
    let errorMessage;
    if (!doesErrorExists(createUserResponse)) {
        const { token } = createUserResponse.response;
        setSessionKeyValue(T_USER_ID, token);
        apiSuccess = true;
    } else {
        apiSuccess = false;
        errorMessage = createUserResponse.response ? createUserResponse.response.message : null;
    }
    callback(apiSuccess, errorMessage)
}

export function* loginUser({ loginDetails, callback }) {
    const loginResponse = yield submitLoginService(loginDetails);
    if (!doesErrorExists(loginResponse)) {
        const { token } = loginResponse.response;
        setSessionKeyValue(T_USER_ID, token);
        buildRedirectUrl();
    } else {
        callback(false, loginResponse.response.message);
    }
}

export function* resetPassword({ email, callback }) {
    const resetPasswordResponse = yield resetPasswordService(email);
    const { message, status } = resetPasswordResponse.response;
    callback(message, status);
}