import React, {Component} from 'react';
import SignInContainer from './containers/SignInContainer';
import {getQueryStringParams} from "./utils/utils";

 class App extends Component {

     constructor(props) {
         super(props);
         const queryParams = getQueryStringParams();
         if (queryParams.local) {
             window.isLocal = true;
         }
         this.props.pageStartUp();
     }

     render() {
         const { props } = this;
         return (
             <div className="App">
                 <SignInContainer {...props}/>
             </div>
         );
     }
}

export default App;
