export const T_USER_ID = 't_user_id';

export const SELECT_DROP_DOWN_STYLES = {
    menu: (provided) => ({
        ...provided,
        borderRadius: '0px',
        'z-index': '9999',
        fontSize: '16px',
    }),
    option: (provided, state) => ({
        ...provided,
        color: state.isSelected ? '#fff' : '#000',
        backgroundColor: state.isSelected ? '#4cb9a3' : '#fff',
        fontSize: '16px',
        "&:hover": {
            backgroundColor: '#4cb9a3',
            color: '#fff',
        },
    }),
    control: (base, state) => ({
        ...base,
        border: state.isFocused ? '1px solid #04464f' : '1px solid #ddd',
        boxShadow: state.isFocused ? '0 0 0 1px #04464f' : 0,
        "&:hover": {
            border: state.isFocused ? '1px solid #04464f' : '1px solid #ddd',
            boxShadow: state.isFocused ? '0 0 0 1px #04464f' : 0,
        },
        fontSize: '16px',
    })
};

export const GENDER_MALE = "MALE";
export const GENDER_FEMALE = "FEMALE";
export const GENDER_OTHER = "OTHER";

export const GENDER = [
    { label: 'select gender', value: '' },
    { label: 'Male', value: GENDER_MALE },
    { label: 'Female', value: GENDER_FEMALE },
    { label: 'Other', value: GENDER_OTHER },
];