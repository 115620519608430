import React, {Component} from 'react';

import RegistrationSection from './RegistrationSection';
import SignInSection from './SignInSection';
import ResetPasswordLink from './ResetPasswordLink';
import Footer from "./Common/Footer";

class SignIn extends Component {

	state = {
		showSignIn: true,
		showForgotPassword: false,
		windowHeight: parseInt(window.innerHeight)-50,
	}

	componentDidMount() {
		window.addEventListener("resize", this.adjustWindowHeight);
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.adjustWindowHeight);
	}

	adjustWindowHeight = () => {
		this.setState({ windowHeight: parseInt(window.innerHeight)-50 });
	}

	toggleSignIn = () => {
		this.setState({ showSignIn: !this.state.showSignIn, showForgotPassword: false });
	};

	showForgotPassword = () => {
		this.setState({ showForgotPassword: true });
	}

	showSignIn = () => {
		this.setState({ showSignIn: true, showForgotPassword: false });
	}

	render() {
		const { loginUser, createUser, resetPasswordLink, isLoggedIn } = this.props;
		const { showSignIn, showForgotPassword, windowHeight } = this.state;
		return (
			<div className="school-app">
				<div className="home-layout" style={{minHeight: windowHeight }}>
					<div className="main schools-section">
						<div className="page-container" style={{maxHeight: windowHeight }}>
							<div className="left-content">
								<div className="outter-wp">
									<div>
										{ !showForgotPassword && !showSignIn &&
										<RegistrationSection
											toggleSignIn={this.toggleSignIn}
											createUser={createUser}
										/>
										}
										{ !showForgotPassword && showSignIn &&
										<SignInSection
											toggleSignIn={this.toggleSignIn}
											showForgotPassword={this.showForgotPassword}
											loginUser={loginUser}
											isLoggedIn={isLoggedIn}
										/>
										}
										{ showForgotPassword &&
										<ResetPasswordLink
											showSignIn={this.showSignIn}
											resetPasswordLink={resetPasswordLink}
										/>
										}
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Footer />
			</div>
		)
	}
}

export default SignIn;