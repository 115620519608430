import React from 'react';
import ReactDOM from 'react-dom';
import {applyMiddleware, createStore} from "redux";
import createSagaMiddleware from 'redux-saga';
import { Provider } from 'react-redux';

import AppContainer from './AppContainer';
import * as serviceWorker from './serviceWorker';

import rootSaga from './sagas/App';
import combineReducers from './reducers/index';
import {getQueryStringParams} from "./utils/utils";

import "./css/index.scss";

const queryParams = getQueryStringParams();
if (queryParams.local) {
    window.isLocal = true;
}

const sagaMiddleware = createSagaMiddleware();
const store = createStore(combineReducers, applyMiddleware(sagaMiddleware));
sagaMiddleware.run(rootSaga);

ReactDOM.render(<Provider store={store}><AppContainer /></Provider>, document.getElementById('root'));


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
