import { connect } from 'react-redux';
import SignIn from '../components/Index';
import {createUser, loginUser, resetPasswordLink} from '../actions';

const mapStateToProps = (state) => {
	return {

	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		loginUser: (loginDetails, callback) => dispatch(loginUser(loginDetails, callback)),
		createUser: (userDetails, callback) => dispatch(createUser(userDetails, callback)),
		resetPasswordLink: (email, callback) => dispatch(resetPasswordLink(email, callback)),
	};
};

const SignInContainer = connect(mapStateToProps, mapDispatchToProps)(SignIn);

export default SignInContainer;