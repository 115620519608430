export const PAGE_START = "PAGE_START";

export const CREATE_USER = "CREATE_USER";

export const LOGIN_USER = "LOGIN_USER";

export const RESET_PASSWORD = "RESET_PASSWORD";

export function pageStartUp() {
    return {
        type: PAGE_START,
    }
}

export function createUser(userDetails, callback) {
    return {
        type: CREATE_USER,
        userDetails,
        callback,
    };
}

export function loginUser(loginDetails, callback) {
    return {
        type: LOGIN_USER,
        loginDetails,
        callback,
    }
}

export function resetPasswordLink(email, callback) {
    return {
        type: RESET_PASSWORD,
        email,
        callback,
    }
}