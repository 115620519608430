import { takeEvery } from 'redux-saga/effects';
import {
	PAGE_START,
	CREATE_USER,
	LOGIN_USER,
	RESET_PASSWORD,
} from '../actions';

import {
	createUser,
	loginUser,
	resetPassword,
} from './UserProfile';

function startPageSetup() {
	// Anything to do on page load
}

export default function* rootSaga() {
	yield takeEvery(PAGE_START, startPageSetup)
	yield takeEvery(CREATE_USER, createUser);
	yield takeEvery(LOGIN_USER, loginUser);
	yield takeEvery(RESET_PASSWORD, resetPassword);
}