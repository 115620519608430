import React, { Component } from 'react';
import {convertDateToString} from "../../utils/utils";

class InputField extends Component {

	handleOnChange = (e) => {
		const { name, value } = e.target;
		const { type } = this.props;
		if( type === 'date') {
			const selectedDate = new Date(value);
			const dateSelected =  selectedDate > new Date() ? convertDateToString(new Date()) : convertDateToString(selectedDate);
			this.props.setFieldValueState(name, dateSelected);
		} else {
			this.props.setFieldValueState(name, value);
		}
	};

	handleOnKeyPress = (e) => {
		this.handleOnChange(e);
		this.props.onKeyPressHandler(e);
	}

	validateField = (e) => {
		const { name, value } = e.target;
		const { validate, setErrorState, isRequired } = this.props;
		let isInValidField;
		if ((validate && !validate(value)) || (isRequired && value === '')) {
			isInValidField = true;
		} else {
			isInValidField = false;
		}
		if (setErrorState) {
			setErrorState(name, isInValidField);
		}
	}

	render() {
		const { type, name, placeholder, isRequired, value, showError, maxLength, min, max, onKeyPressHandler } = this.props;
		let className = type === 'date' ? 'custom-date-form-control' : 'form-control';
		className = showError ? `${className} input-error` : className;

		const inputProps = {
			type,
			className,
			name,
			placeholder,
			value,
			maxLength,
			min,
			max,
			required: isRequired,
			onChange: this.handleOnChange,
			onBlur: this.validateField,
		};

		if(onKeyPressHandler) {
			inputProps.onKeyPress = this.handleOnKeyPress;
		}

		return (
			<input {...inputProps}></input>
		);
	}
}

export default InputField;