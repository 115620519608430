import {
	getRequestInfo,
	callHandleServiceRequest
} from './ServiceHandler';

import {
	POST_METHOD,
	SIGN_UP_URL,
	SIGN_IN_URL,
	RESET_PASSWORD_URL,
} from '../constants/urls';

export function createUserService(userDetails) {
	const requestInfo = getRequestInfo(SIGN_UP_URL);
	return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(userDetails));
}

export function submitLoginService(loginDetails) {
	const requestInfo = getRequestInfo(SIGN_IN_URL);
	return callHandleServiceRequest(requestInfo, POST_METHOD, JSON.stringify(loginDetails));
}

export function resetPasswordService(email) {
	const requestInfo = getRequestInfo(RESET_PASSWORD_URL);
	requestInfo.url = `${requestInfo.url}?email=${email.email}`;
	return callHandleServiceRequest(requestInfo, POST_METHOD, null);
}