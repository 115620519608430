import { LOCAL_DOMAIN, PROD_DOMAIN, API_REQUEST_TIMEOUT } from '../constants/urls';
import {isLocalHostDevelopment} from "../utils/utils";

export function getRequestInfo(urlPath, ignoreContentType = false) {
	const url = isLocalHostDevelopment() ? `${LOCAL_DOMAIN}${urlPath}` : `${window.location.protocol}//${PROD_DOMAIN}${urlPath}`;
	const headers = {
		'Accept': 'application/json; charset=utf-8',
	};
	if (!ignoreContentType) {
		headers['Content-Type'] = 'application/json; charset=utf-8';
	}
	return {
		url,
		headers,
	};
}

export function getHandleServiceRequest(promiseFunction) {
	return new Promise((resolve, reject) => {
		return promiseFunction(resolve, reject);
	})
		.then((response) => {
			return {
				response: response || true,
			}
		})
		.catch((error) => {
			return {
				error,
			}
		})
}

export function callHandleServiceRequest( requestInfo, methodType, bodyParams) {
	const options = {
		method: methodType,
		headers: requestInfo.headers,
		timeout: API_REQUEST_TIMEOUT,
		cache: 'no-cache',
	}

	if (methodType !== 'GET' && methodType !== 'HEAD') {
		options.body = bodyParams;
	}

	return getHandleServiceRequest((resolve, reject) => {
		fetch(requestInfo.url, options)
			.then((res) => {
				const status = res.status;
				res.json()
					.then((json) => {
						json.status = status;
						resolve(json);
					})
					.catch(() => { reject({ status, errors: true }) });
			})
			.catch((errors) => { reject({errors}) });
	});
}